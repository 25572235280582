angular.module('fingerink')
    .service('createContactModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance =  $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/createContactModal.tpl.html',
                controller: 'createContactModalCtrl',
                animation: false,
                size: 'xl'
            });
            
            return this.modalInstance;
        };

    })
    .controller('createContactModalCtrl',function createContactModalCtrl($rootScope, $scope, $uibModalInstance, contactService, $translate, $state) {

            $scope.grupofill = 0;
            $scope.creandocontacto = false;
            $scope.grupos = {};

            contactService.getContactosGrupos().then(function (response) {
                $scope.grupos = response.data;
            });
            
            $scope.close = function () {
                $uibModalInstance.close();
            };

            $scope.enviarcontacto = function () {

                $scope.creandocontacto = true;
                if (!$scope.ncontacto.email || !$scope.ncontacto.name) {
                    swal($translate.instant("Algo ha ido mal"), $translate.instant("Es necesario rellenar el email y el nombre como mínimo"), 'info');
                    $scope.creandocontacto = false;
                    return;
                }

                contactService.addContact($scope.ncontacto).then(function (response) {
                    //Insertar en el grupo
                    if ($scope.grupofill > 0) {
                        contactService.getContactoss($scope.ncontacto).then(function (response) {
                            $scope.contacto_aux = response.data;
                            contactService.getContactosGrupos({
                                id: $scope.grupofill
                            }).then(function (response) {
                                $scope.grupo_aux = response.data;
                                $scope.grupo_aux[0].groupUsers.push($scope.contacto_aux[0]);
                                contactService.updateContactoGrupo($scope.grupo_aux[0]).then(function (response) {
                                    swal($translate.instant('¡Correcto!'), $translate.instant('Se ha añadido el usuario a la agenda y al grupo seleccionado'), 'success');
                                    $uibModalInstance.close();
                                    $scope.creandocontacto = false;
                                    $state.go('main.contacts');
                                    
                                    
                                });
                            });
                        });
                    } else {
                        swal($translate.instant('¡Correcto!'), $translate.instant('Se ha añadido el usuario a la agenda'), 'success');
                        $scope.creandocontacto = false;
                        $state.go('main.contacts');
                        $uibModalInstance.close();
                    }
                });
            };

        });